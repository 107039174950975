import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\node_modules\\next\\dist\\client\\legacy\\image.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\public\\assets\\images\\contact.png");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\public\\assets\\images\\emptyImage.png");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\public\\assets\\images\\manifest-icon-192.png");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\public\\assets\\images\\manifest-icon-512.png");
;
import(/* webpackMode: "eager" */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\public\\assets\\images\\shipment.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\app\\[lng]\\(home)\\components\\HomeCarousel.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\app\\[lng]\\(home)\\components\\products-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\ContactUS.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\YosefMajadley\\source\\repos\\Codit_ECommerce.CLIENT\\web\\src\\components\\shipments\\shipments-cities.tsx");
